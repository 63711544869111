import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

interface ISEO {
  title?: string;
  description?: string;
  image?: string;
}

const SEO: React.FC<ISEO> = ({ title, description, image }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const metaData = site.siteMetadata;

  const seoData = {
    title: title ?? metaData.title,
    description: description ?? metaData.description,
    image: image ?? metaData.image,
    url: `${metaData.url}${pathname}`
  };

  return (
    <Helmet
      title={title ? `${seoData.title} | ${metaData.title}` : metaData.title}
    >
      <meta name="description" content={seoData.description} />
      <meta name="image" content={seoData.image} />
      {seoData.url && <meta property="og:url" content={seoData.url} />}
      {seoData.title && <meta property="og:title" content={seoData.title} />}
      {seoData.description && (
        <meta property="og:description" content={seoData.description} />
      )}
      {seoData.image && <meta property="og:image" content={seoData.image} />}
      {seoData.title && <meta name="twitter:title" content={seoData.title} />}
      {seoData.description && (
        <meta name="twitter:description" content={seoData.description} />
      )}
      {seoData.image && <meta name="twitter:image" content={seoData.image} />}
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`;
